import Init from '@/model/Init';
import store from "@/store";

const date = {
  message(field){
    return field + "に正しい日付を入力してください。";
  },
  validate(value){
    const regExp = /^(\d{4})-(\d\d?)-(\d\d?)$/;
    let matches = value.match(regExp);
    let isValid = matches;
    let maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    
    if (matches) {
      const year = parseInt(matches[1]);
      const month = parseInt(matches[2]);
      const date = parseInt(matches[3]);
      
      
      isValid = month <= 12 && month > 0;
      isValid &= date <= maxDate[month] && date > 0;
      
      const leapYear = (year % 400 == 0)
         || (year % 4 == 0 && year % 100 != 0);
      isValid &= month != 2 || leapYear || date <= 28; 
    }
    return isValid
  },
};


const noSpecialChar = {
  message(field){
    return field + "に特別文字列を登録出来ません。"
  },
  validate(value){
    return !/[*|\\":<>[\]{}`\\()';.@&$\\/#]/.test(value);
  }
}

const yearOnly = {
  message(field){
    return field + "に正しい年(YYYY)を登録してください。"
  },
  validate(value){
    if(!value){
      return false;
    }
    return value.toString().match(/^\d{4}$/);
  }
}

const houjin = {
  message(){
    return "正しい法人番号を登録してください。";
  },
  validate(value){
    let totalEven = 0;
    let totalOdd = 0;
    value = value.toString().split("");
    for (let i = 1; i <= value.length - 1; i++) {
      if ((i - 1) % 2 === 0) {
        totalEven += parseFloat(value[i]);
      } else {
        totalOdd += parseFloat(value[i]);
      }
    }
    return (9 - (totalEven * 2 + totalOdd) % 9) === parseFloat(value[0]);
  },
}
const numeric = {
  message(field){
    return field+"は半角数字のみ使用できます。";
  }
}

const numerichypen = {
  message(field){
    return field+"は半角数字にてご入力ください。";
  },
  validate(value){
    return /^[0-9 -]+$/.test(value);
  },
}

const numericPostalNotHypen = {
  message(field){
    return field+"はハイフンなし、半角数字７桁にてご入力ください。";
  },
  validate(value){
    return /^[0-9]{7}$/.test(value);
  },
}

const numericFinancial = {
  message(field){
    return field+"には数値をご入力ください。";
  },
  validate(value){
    return value != '-';
  },
}

const numericYear = {
  message(){
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    return "1000年～"+currentYear+"年の範囲にてご入力ください。";
  },
  validate(value){
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    return /^[0-9]{4}$/.test(value) && currentYear >= value;
  },
}

const phonenumber = {
  message(field){
    return field+"はハイフン「-」あり9桁以上をご入力ください。"
  },
  validate(value){
    return /^0\d{1,3}-\d{1,4}-\d{4}$/.test(value);
  }
}

const katakanaOnly = {
  message(field){
    return field + "はカタカナでご入力下さい";
  },
  validate(value){
    return /^([ァ-ヴ][ァ-ヴー・\u3000\x20]*)$/.test(value);
  }
}

const romajiOnly = {
  message(field){
    return field + "は英字でご入力下さい";
  },
  validate(value){
    return /^[a-zA-Z]*$/.test(value);
  }
}

const passwordStrength = {
  validate(value, arg) {
    const companyCode =
      Array.isArray(arg) && arg.length
        ? arg[0]
        : store.state.user.companyCode;
    return new Promise((resolve) => {
      Init.validatePassword({
        companyCode: companyCode,
        password: value
      }).then(response => {
        if(response.data.valid) resolve(true);
        else resolve(response.data.invalidReasons.join('\n'));
      }).catch(() => { resolve(false); });
    });
  }
}


const noFullWidthNumbers = {
  message(field){
    return field+"は半角数字にてご入力ください。";
  },
  validate(value){
    return !/[０-９]/.test(value);
  },
}

const notEqual = {
  params: ['target'],
  validate(value, { target }){
    return value !== target;
  },
  message: '{_field_}と{target}は一致しない様にご入力ください。'
}


export {
  date, houjin, yearOnly, numeric, numerichypen, numericPostalNotHypen, numericYear, numericFinancial, phonenumber, passwordStrength, notEqual, katakanaOnly, romajiOnly, noFullWidthNumbers, noSpecialChar
}