import Vue from "vue";
import Router from "vue-router";
import $store from "@/store";
import Login from '@/model/Login.js';

/* Fix bug: NavigationDuplicated: Avoided redundant navigation to current location "</eProcurement/....>" */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

const router = new Router({
  mode: "history", // Use browser history
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("./pages/Login"),
      meta: { unauthenticated: true }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./pages/Login"),
      meta: { unauthenticated: true }
    },
    {
      path: "/LoginDemo",
      name: "LoginDemo",
      component: () => import("./pages/LoginDemo"),
      meta: { unauthenticated: true }
    },
    {
      path: "/auth/singleSignOut",
      component: () => import("./pages/SingleSignOut"),
      name: "sso_logout",
      meta: { unauthenticated: true }
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: () => import("./pages/ResetPassword"),
      meta: { unauthenticated: true }
    },
    {
      path: "/validate-device",
      name: "ValidateDevice",
      component: () => import("./pages/ValidateDevice"),
      meta: { unauthenticated: true }
    },
    {
      path: "/pagenotfound",
      name: "PageNotFound",
      component: () => import("./pages/PageNotFound"),
      meta: { common: true }
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("./pages/Logout"),
      meta: { logout: true }
    },
    /*
    --------------------------------------------------
    // Initial Registration
    --------------------------------------------------
    */
    {
      path: "/initiate/policy",
      name: "InitiatePolicy",
      component: () => import("./pages/initiate/Policy.vue"),
      meta: { unauthenticated: true }
    },
    {
      path: "/initiate/mail-address",
      name: "InitiateMailAddress",
      component: () => import("./pages/initiate/MailAddress.vue"),
      meta: { unauthenticated: true }
    },
    {
      path: "/initiate/mail-address-complete",
      name: "InitiateMailAddressComplete",
      component: () => import("./pages/initiate/MailAddressComplete.vue"),
      meta: { unauthenticated: true }
    },
    {
      path: "/initiate/confirm/:authCode",
      name: "InitiateConfirm",
      component: () => import("./pages/initiate/Confirm.vue"),
      meta: { unauthenticated: true }
    },
    {
      path: "/invitation/:temporaryPassword",
      name: "SetupPassword",
      component: () => import("./pages/SetupPassword"),
      meta: { common: true }
    },
    {
      path: "/password-reset/:temporaryPassword",
      name: "SetupPassword",
      component: () => import("./pages/SetupPassword"),
      meta: { common: true }
    },
    // Add request reset password page
    // Copy from commit: "DEV-2023-5345 : change password rule" in develop branch
    {
      path: "/request/reset-password",
      name: "RequestResetPassword",
      component: () => import("./pages/RequestResetPassword"),
      meta: { common: true }
    },
    {
      path: "/initiate/inviter-policy",
      name: "InviterPolicy",
      component: () => import("./pages/initiate/InviterPolicy.vue"),
      meta: { unauthenticated: true }
    },
    {
      path: "/initiate/registration",
      name: "InitiateRegister",
      component: () => import("./pages/initiate/InitiateWizard.vue"),
      children: [ 
        { name:"InitiateCompanyInfo", path: "company-info", component: () => import("./pages/initiate/RegisterCombined.vue"),    meta: { temporary: true } },
        { name:"InitiateConfirmation", path: "confirmation", component: () => import("./pages/initiate/Confirmation.vue"), meta: { temporary: true } },
      ],
      meta: {
        temporary: true
      }
    },
    
    /*
    --------------------------------------------------
    // Supplier Manage
    --------------------------------------------------
    */
    {
      path: "/supplier/dashboard",
      name: "Dashboard",
      component: () => import("./pages/manage/Dashboard.vue"),
      meta: {
        
      }
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("./pages/Profile.vue"),
      meta: {
        
      }
    },
    {
      path: "/supplier/manage",
      name: "MainMenu",
      component: () => import("./pages/manage/MainMenu.vue"),
      meta: {
        
      }
    },
    {
      path: "/supplier/registration",
      name: "RegistrationWizard",
      component: () => import("./pages/manage/RegistrationWizard.vue"),
      children: [
        {path: "buyer-info", component: () => import("./pages/manage/BuyerInfo.vue"), meta:{code:"S999"}},
        {path: "category-info", component: () => import("./pages/manage/CategoryInfo.vue"), meta:{code:"S999"}},
        {path: "product-name-info", component: () => import("./pages/manage/ProductNameInfo.vue"), meta:{code:"S999"}},
        {path: "delivery-location-info", component: () => import("./pages/manage/DeliveryLocationInfo.vue"), meta:{code:"S999"}},
        {path: "addon-info", component: () => import("./pages/manage/CompanyAddonInfo.vue"), meta:{code:"S999"}},
        {path: "verification-info", component: () => import("./pages/manage/VerificationInfo.vue"), meta:{code:"S999"}},
        // {path: "delivery-price", component: () => import("./pages/manage/DeliveryPrice.vue"), meta:{code:"S999"}},
        {path: "pr", component: () => import("./pages/manage/PublicRelations.vue"), meta: { code: "S999" }},
        {path: "payment-account-info", component: () => import("./pages/manage/PaymentAccountInfo.vue"), meta:{code:"S999"}},
        {path: "office-verification-info", component: () => import("./pages/manage/OfficeVerificationInfo.vue"), meta:{code:"S999"}},
        {path: "register-signer", component: () => import("./pages/manage/RegisterOrRequestSign.vue"), meta:{ }},
        {path: "registration-finish", component: () => import("./pages/manage/RegistrationFinish.vue"), meta:{code:"S999"}},
      ],
      meta: {
        //code: "S999"
      }
    },
    {
      path: "/supplier/manage/buyer-info",
      name: "BuyerInfo",
      component: () => import("./pages/manage/BuyerManage.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/buyer-info/add",
      name: "BuyerInfoAdd",
      component: () => import("./pages/manage/BuyerInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/category-info",
      name: "CategoryInfo",
      component: () => import("./pages/manage/CategoryInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/company-info",
      name: "CompanyInfo",
      component: () => import("./pages/manage/CompanyInfoView.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/company-info/edit",
      name: "CompanyInfoEdit",
      component: () => import("./pages/manage/CompanyInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/company-addon-info/edit",
      name: "CompanyInfoAddonEdit",
      component: () => import("./pages/manage/CompanyAddonInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/contract-info",
      name: "ContractInfo",
      component: () => import("./pages/manage/ContractInfo.vue"),
      meta: {
        code: "SB20"
      }
    },
    {
      path: "/supplier/manage/delivery-location-info",
      name: "DeliveryLocationInfo",
      component: () => import("./pages/manage/DeliveryLocationInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/payment-account-info",
      name: "PaymentAccountInfo",
      component: () => import("./pages/manage/PaymentAccountInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/product-name-info",
      name: "ProductNameInfo",
      component: () => import("./pages/manage/ProductNameInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/pr",
      name: "PublicRelations",
      component: () => import("./pages/manage/PublicRelations.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/pr/preview",
      name: "PublicRelationsPreview",
      component: () => import("./pages/manage/PublicRelationsPreview.vue"),
      meta: {
        code: "S999"
      },
      props: true
    },
    {
      path: "/supplier/manage/signer-info",
      name: "SignerInfoView",
      component: () => import("./pages/manage/SignerInfoView.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/signer-info/edit",
      name: "SignerInfo",
      component: () => import("./pages/manage/SignerInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/user-info",
      name: "UserInfo",
      component: () => import("./pages/manage/UserInfoView.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/user-info/edit",
      name: "UserInfoEdit",
      component: () => import("./pages/manage/UserInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/verification-info",
      name: "VerificationInfo",
      component: () => import("./pages/manage/VerificationInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    // {
    //   path: "/supplier/manage/delivery-price",
    //   name: "DeliveryPrice",
    //   component: () => import("./pages/manage/DeliveryPrice.vue"),
    //   meta: {
    //     code: "S999"
    //   }
    // },
    {
      path: "/supplier/manage/office-verification-info",
      name: "OfficeVerificationInfo",
      component: () => import("./pages/manage/OfficeVerificationInfo.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/supplier/manage/invoice",
      name: "Invoice",
      component: () => import("./pages/manage/Invoice.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/open-invitation",
      name: "OpenInvitation",
      component: () => import("./pages/OpenInvitation.vue")
    },
    {
      path: "/supplier/manage/mail-config",
      name: "MailConfig",
      component: () => import("./pages/manage/MailConfig.vue"),
      meta: {
        code: "S999"
      }
    },
    {
      path: "/eProcurement/*",
      name: "eProcurement",
      component: () => import("./pages/eProcurement.vue")
    },
    // BUG2023-2043
    {
      path: "/PDFView",
      name: "PDFView",
      component: () => import("./pages/FooterSetting/PDFView.vue"),
      meta: { common: true }
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  setTimeout(() => {
    if(document.getElementById("main-sidebar")){
      document.getElementById("main-sidebar").className = "sidebar";
      $store.state.isMainMenuOpen = false;
    }
  },100);
  console.log("beforeEach", to, from);
  if(to.meta.common){
    next();
  }else if(to.meta.unauthenticated){
    if (to.name === "sso_logout") {
      next();
    } else {
      $store.state.logged ? next({name: 'MainMenu'}) : next();
    }
  }else if(to.meta.temporary){
    $store.state.temporary ? 
      next() : 
      next({name : "PageNotFound"});
  }else if($store.state.logged) {
    const storeState = $store.state;
    const storedSS0 = storeState.sso || {};
    const jtiToken = storeState.user.jtiToken;

    // handle sync sso data from UserSession -- start
    if (storedSS0 && storedSS0.isSyncData) {
      // $eProcurement.setHeadersAccessToken($store.state.accessToken);
      // handle sync sso data from BE
      const response = await Login.getSingleSignOnData(jtiToken).catch((error) => console.log(error));
      if (response && Object.keys(response.data).length > 0) {
        const sso = Object.assign(response.data, {
          isSyncData: false
        });
        $store.commit("setSingleSignOn", sso);
      } else {
        let doneSingleSignOutUnderBackEnd = storedSS0.singleSignOnList && storedSS0.singleSignOnList.length > 0;
        /* TODO SingleSignOn Flow
        const tokenParam = toQuery.Token;
        if (requestSingleSignOn && tokenParam && tokenParam !== "" && doneSingleSignOutUnderBackEnd) {
          const singleSignOnList = storedSS0.singleSignOnList;
          const indexResult = singleSignOnList.findIndex((item) => item.token === tokenParam);
          doneSingleSignOutUnderBackEnd = indexResult > -1;
          // [doneSingleSignOutUnderBackEnd] is equal to FALSE --> request new SSO after BE handled SingleSignOut for previous SSO
          // [doneSingleSignOutUnderBackEnd] is equal to TRUE --> request new SSO with Old SSO Info that is handled SingleSignOut for previous SSO under BE
        }
        */

        if (doneSingleSignOutUnderBackEnd) {
          // done SingleSignOut under BE
          // --> logout Current SSO
          $store.commit('logout');
          if (storedSS0.redirectUrl && storedSS0.redirectUrl !== "") {
            window.location.replace(storedSS0.redirectUrl);
          } else {
            next({ name: "Login" });
          }
          return;
        }
      }
    } else if (Object.keys(storedSS0).length > 0 && typeof storedSS0.isSyncData === "boolean") {
      Login.getSingleSignOnData(jtiToken).then((response) => {
        if (response && response.data) {
          const sso = Object.assign(response.data, {
            isSyncData: false
          });
          $store.commit("setSingleSignOn", sso);
        }
      }).catch((error) => console.log(error));
    }
    // handle sync sso data from UserSession -- start
    
    if(to.meta.code)  {
      var authenticated = $store.state.user.roles.find(r => r.roleCode === to.meta.code) ? true : false;
      if(authenticated){
        next();
      }else{
        next({ name: "PageNotFound", query: {unauthorized: true}}); 
      }
    }else next();
  }else next({ name: "Login", query: { redirect: to.fullPath }})
});


export default router;