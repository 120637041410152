<template>
  <select>
    <input type='text' class='form-control'>
    <slot></slot>
  </select>
</template>

<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<script>
export default {
  props: ["value", "hasError"],
  mounted: function() {
    // khanhlt fix bug https://jira.jienie.cloud/browse/FRT-1460
    let config = {
      theme: "bootstrap4",
      width: "100%",
      language: "ja",
      maximumInputLength: 50
    };
    if (this.$parent.$el === $("div.modal")[0]) {
      config = { ...config, dropdownParent: this.$parent.$el };
    }
    var self = this;
    $(this.$el)
      .select2(config)
      .val(this.value)
      .trigger("change")
      .on("change", function() {
        self.$emit("input", this.value === "" ? null : this.value);
      });
  },
  watch: {
    value: function(value) {
      $(this.$el)
        .val(value)
        .trigger("change");
      this.$emit("change");
    },
    hasError() {
      this.$nextTick(() => {
        if (this.hasError) {
          $(this.$el)
            .next()
            .find(".select2-selection")
            .addClass("has-error");
        } else {
          $(this.$el)
            .next()
            .find(".select2-selection")
            .removeClass("has-error");
        }
      });
    }
  },
  destroyed: function() {
    $(this.$el)
      .off()
      .select2("destroy");
  }
};
</script>

<style>
/* custom select2 */
.select2-container--bootstrap4 .select2-selection--single {
    height: calc(2.25rem + 2px) !important;
    line-height: 1;
    padding: 0.75rem 1.125rem 0.75rem 0.375rem;
}
</style>
