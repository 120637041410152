<template>
  <div id="app" v-if="$route">
    <template v-if="separateDisplaying || currentRouteName === 'sso_logout'">
      <router-view/>
    </template>
    <template v-else>
      <Header></Header>
      <div :class="'main-window ' + (($store.state.logged || $store.state.temporary) || 'no-top')">
        <!-- Update condition Sidebar display
        Copy from commit: "DEV-2023-5345 : change password rule" in develop branch -->
        <Sidebar v-if="
          $store.state && 
          $store.state.user && 
          !$store.state.user.modifyPasswordFlg &&
          !$store.state.user.isPasswordExpired &&
          $store.state.user.status && 
          $store.state.user.status.accountCreated"></Sidebar>
        <div :class="{'main-content':true, 'has-sidebar': $store.state.logged}">
          <router-view/>
          <div id="main-overlay" class="loading-overlay">
            <b-spinner variant="primary" class="mx-auto my-auto" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Base from '@/model/_base';

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
 
export default {
  name: "app",
  components: {
    Header, Footer, Sidebar
  },
  data: (()=> {
    return {
      showSidebar: false,
      separateDisplaying: this && this.$route && this.$route.name === 'sso_logout'
    }
  }),
  computed: {
    currentRouteName() {
      return this.$route ? this.$route.name : null;
    }
  },
  created() {
    
    this.separateDisplaying = this.$route.name === 'sso_logout';
    axios.defaults.headers.common['Authorization'] = this.$store.state.accessToken;
    axios.interceptors.request.use(function(config) {
      const mainOverylayElement = document.getElementById("main-overlay");
      if (mainOverylayElement) {
        // skip "Cannot set property 'className' of null"
        if(!config.noLoading)
          mainOverylayElement.className = "loading-overlay show";
      }
      return config;
    }, function(error) {
      const mainOverylayElement = document.getElementById("main-overlay");
      if (mainOverylayElement) {
        // skip "Cannot set property 'className' of null"
        mainOverylayElement.className = "loading-overlay";
      }
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function(response) {
      const mainOverylayElement = document.getElementById("main-overlay");
      if (mainOverylayElement) {
        // skip "Cannot set property 'className' of null"
        mainOverylayElement.className = "loading-overlay";
      }
      return response;
    }, error => {
      const mainOverylayElement = document.getElementById("main-overlay");
      if (mainOverylayElement) {
        // skip "Cannot set property 'className' of null"
        mainOverylayElement.className = "loading-overlay";
      }
      if(error.response && error.response.config && error.response.config.disableSessionTimeout){
        return Promise.reject(error);
      }
      if( error.response.status == 403){
        if(this.$store.state.logged){
          this.$errorMessage("セッションの有効期限が切れています。再度ログインを行なってください。", {always: true});
        } 
        this.$store.commit('logout');
        if(!url.pathname.toLowerCase.startsWith('/logindemo')){
          this.$router.push('/login');
        }
      }
      return Promise.reject(error);
    });

    var url = new URL(window.location.href);
    if(!url.pathname.startsWith('/initiate') && url.pathname != '/' && url.pathname != '/logout' && url.pathname != '/auth/singleSignOut'  &&  !url.pathname.startsWith('/invitation') ){
      axios.get(Base.baseURL + 'status/token').then(()=>{});
    }
    this.setLocaleMessage();
  },  
  methods: {
    setLocaleMessage(){
      // TODO turn this to be based on the users current locale
      const defaultLocaleCode = 'ja';
      const localeCode = this.$store.state.user.languageCode || defaultLocaleCode;
      const loadLocale = async() => {
        const locale = await import(`@/generated-locales/${localeCode}.json`);
        this.$i18n.setLocaleMessage("selfLocale", locale);
      }
      loadLocale().catch(reason => {
        this.$errorMessage(reason);
      });
    }
  }
};
</script>

<style lang="scss">
  @import './assets/css/main.scss';
  .main-content{
    position:relative;
  }
  .main-window{
    padding-top: 70px;
    min-height: 100vh;
    display:flex;
    .main-content {
      flex: 1;
      &.has-sidebar{
        margin-left: 86px;
      }
    }
    &.no-top{
      padding-top: 0;
      .main-content { padding-top: 70px; }
    }
  }

  .loading-overlay{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    height: 100%;
    width: 100%;
    z-index: 400;
    display:none;
    &.show{
      display:flex;
    }
  }

  @media (max-width: 992px) {
    .main-window {
      .main-content {
        margin-left:0;
      }
    }
  }

</style>