<template>
  <j-sidebar v-slot="{ toggled, onMenuOpen }" class="w-auto">
    <j-sidebar-item v-if="( hasRole('ST01') || hasRole('S001') ) && activated" :sidebar-open="toggled" @open="onMenuOpen">
      <template #icon><font-awesome-icon icon="file-invoice-dollar"></font-awesome-icon></template>
      <template #title>{{$t('ESTIMATE_LIST.TITLE')}}</template>
      <template #menu>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/All?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.ALL')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/BeforeAnswer?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.UNANSWER')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/Estimate/AfterAnswer?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.ANSWERED')}}</b-nav-item>
      </template>
    </j-sidebar-item>
    <j-sidebar-item v-if="hasRole('S001') && activated" :sidebar-open="toggled" @open="onMenuOpen">
      <template #icon><font-awesome-icon icon="calendar-check"></font-awesome-icon></template>
      <template #title>{{$t('ORDER_LIST.TITLE')}}</template>
      <template #menu>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/All?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.ALL')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/TemporaryEstimate?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.INITIAL_RECEIVE')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/AcceptingOrder?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.ORDER_RECEIVE')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/WaitingDelivery?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.UNDELIVERED')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/CompletedDelivery?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.DELIVERED')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/CompletedAcceptance?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('COMMON.ACCEPTED')}}</b-nav-item>
      </template>
    </j-sidebar-item>
    <j-sidebar-item v-if="$store.state.logged && hasRole('S999')" :sidebar-open="toggled" @open="onMenuOpen">
      <template #icon><font-awesome-icon icon="wrench"></font-awesome-icon></template>
      <template #title>{{$t('SYSTEM_MANAGEMENT.TITLE')}}</template>
      <template #menu>
        <b-nav-item to="/supplier/manage">{{$t('HOME.TITLE')}}</b-nav-item>
        <b-nav-item to="/supplier/dashboard" v-if="activated && isOpen">{{$t('ANALYTICS.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/company-info">{{$t('COMPANY_INFO.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/user-info">{{$t('USERS.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/category-info">{{$t('CATEGORY.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/product-name-info">{{$t('PRODUCT_NAME.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/delivery-location-info">{{$t('DELIVERY_LOCATION.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999')" to="/supplier/manage/verification-info">{{$t('VERIFICATION_INFO.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999') && isOpen" to="/supplier/manage/payment-account-info">{{$t('PAYMENT_ACCOUNT.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999') && activated && isOpen" to="/supplier/manage/buyer-info">{{$t('BUYER_INFO.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999') && activated && isOpen" to="/supplier/manage/marketplace">{{$t('MARKETPLACE.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999') && registerCompleted" to="/supplier/manage/pr">{{$t('PR_INFO.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('SB20') && examinationCompleted" to="/supplier/manage/contract-info">{{$t('CONTRACT_INFO.TITLE')}}</b-nav-item>
        <b-nav-item v-if="hasRole('S999') && registerCompleted" to="/supplier/manage/signer-info">{{$t('SIGNER_INFO.TITLE')}}</b-nav-item>
      </template>
    </j-sidebar-item>
    <j-sidebar-item v-if="hasRole('SC004') && activated" :sidebar-open="toggled" @open="onMenuOpen">
      <template #icon><font-awesome-icon icon="credit-card"></font-awesome-icon></template>
      <template #title>{{$t('COMMON.OTHER')}}</template>
      <template #menu>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(j2Url + '/eProcurement/Supplier/PaymentManagement?' + singleSignOnParam)" class="nav-link" target="_self">{{$t('PAYMENT_NOTICE.TITLE')}}</b-nav-item>
      </template>
    </j-sidebar-item>
    <j-sidebar-item v-if="(hasRole('UR002') || hasRole('UR003')) && activated" :sidebar-open="toggled" @open="onMenuOpen">
      <template #icon><font-awesome-icon class="va-m" icon="book"></font-awesome-icon></template>
      <template #title>{{$t('CATALOG_MANAGEMENT.TITLE')}}</template>
      <template #menu>
        <!-- JNM-1416 <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/WkProductDetailNew?isMarketplace=1&' + singleSignOnParam, false)" class="nav-link" target="_blank">{{$t('MARKETPLACE.TITLE')}}</b-nav-item> -->
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductSearch?' + singleSignOnParam, false)" class="nav-link" target="_blank">{{$t('PRODUCT_SEARCH.TITLE')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductApplicationList?' + singleSignOnParam, false)" class="nav-link" target="_blank">{{$t('PRODUCT_MANAGEMENT.TITLE')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/SupplierPublicList?' + singleSignOnParam, false)" class="nav-link" target="_blank">{{$t('MARKETPLACE_MANAGEMENT.TITLE')}}</b-nav-item>
        <b-nav-item href="javascript:void(0)" @click.prevent="redirectSsoLink(cmUrl + '/ProductUploadResult?' + singleSignOnParam, false)" class="nav-link" target="_blank">{{$t('PRODUCT_UPLOAD.TITLE')}}</b-nav-item>
      </template>
    </j-sidebar-item>
  </j-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      j2Url: process.env.VUE_APP_J2_URL,
      cmUrl: process.env.VUE_APP_CM_URL,
      jti: this.$store.state.user.jtiToken
    }
  },
  components: {
    
  },
  computed: {
    registerCompleted:  function(){ 
      if(this.$store.state.user){
        return this.$store.state.user.status.registrationCompleted ;
      }
      return null;
    } , 
    examinationCompleted: function(){ 
      if(this.$store.state.user){
        return this.$store.state.user.status.examinationCompleted ;
      }
      return null;
    },
    activated: function(){ 
      if(this.$store.state.user){
        return this.$store.state.user.status.completed ;
      }
      return null;      
    },
    isOpen: function(){
      if(this.$store.state.user){
        return this.$store.state.user.status.openSupplier ;
      }
      return null;      
    },
    ...mapGetters(["singleSignOnParam"])
  },
  mounted: function(){
    
  },
  methods: {
    j2Click: function () {
      this.$store.commit('j2Login');
    },
    hasRole(roleCode){
      if(!this.$store.state.logged) return false;
      for(var i=0; i < this.$store.state.user.roles.length; i++){
        
        if(this.$store.state.user.roles[i].roleCode === roleCode){
          return true;
        }
      }
      return false;
    },
    redirectSsoLink(ssoLink, isCurrentTab = true) {
      if (!ssoLink) {
        return;
      }
      this.$store.dispatch('redirectSingleSignOnLink', {ssoLink, isCurrentTab})
    }
  },
}
</script>