<template>
  <div class="clearfix"> </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
  .clearfix{
    clear:both;
  }
</style>
