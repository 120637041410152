
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optionalimport Vue from "vue";

import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueI18n from "vue-i18n";

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';
import * as customRules from '@/plugins/validation-rules';
import * as customFilters from '@/plugins/filters';

import App from "./App.vue";
import router from "./routes";
import store from "@/store";

import VueSanitize from "vue-sanitize";

import { Plugins, Components } from 'jds';

Vue.config.productionTip = false;
Vue.use(Plugins);
Vue.use(Components);
Vue.use(VueSanitize);


// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Object.keys(customRules).forEach(rule => {
  extend(rule, customRules[rule]);
});

Object.keys(customFilters).forEach(filter => {
  Vue.filter(filter, customFilters[filter]);
});

localize('ja', ja);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VueReCaptcha, { 
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions:{
    autoHideBadge: true
  }
})
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "selfLocale",
  messages: { selfLocale: {} },
  silentTranslationWarn: true // mkh013 set console no show warning
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
