import Model from './_base';

export default class Init extends Model {
    static entity = 'init';
    
    constructor() {
        super(Init.entity);
        this.email = "";
        this.corporateNumber = "";
    }

    static save(form){
      return Model.axios.post(this.url(''), form);  
    }

    static confirm(authCode){
      return Model.axios.get(this.url('?authCode=' + authCode));
    }
    
    static setupPassword(data){
      return Model.axios.post(this.url('/setupPassword'), data);
    }

    static validatePassword(data){
      return Model.axios.post(this.url('/validate-password'), data, {
        noLoading:true
      });
    }
    
    static getRoles(spotSupplier = false){
      return Model.axios.get(this.url('/roles?spot_supplier='+spotSupplier));
    }
}