import Model from './_base';

export default class Login extends Model {
    static entity = 'login';
    
    constructor() {
        super(Login.entity);
        this.companyCode = "";
        this.email = "";
        this.password = "";
    }

    static login(data){
      return Model.axios.post(this.urlServer(""), data, {
        withCredentials: true,
        disableSessionTimeout: true
      });
    }

    static invitationLogin(data){
      return Model.axios.post(this.absolute('api/auth/invitation'), data, {
        headers: {
          'Content-Type': 'text/plain'
        },
      });
    }

    static validateDevice(data){
      return Model.axios.post(this.absolute("api/auth/validate-device"), data, {withCredentials: true});
    }

    static resetPassword(data){
      return Model.axios.post(this.absolute("api/auth/reset-password"), data);
    }

    static getLanguages(locale){
      return Model.axios.get(process.env.VUE_APP_MULTI_LANG + locale + ".json");
  }

  static singleSignOut(token, singleSignOnInfo){
    return Model.axios.post(`${Model.baseURL}auth/sso/logout?token=${token}`, singleSignOnInfo, { headers: { "Authorization": "" } });
  }

  static getSingleSignOnData(token){
    return Model.axios.get(`${Model.baseURL}auth/sso/sso-data?token=${token}`, { headers: { "Authorization": "" } });
  }

  static switchUserOrg(data) {
    return Model.axios.post(this.absolute("api/auth/switch-user-org"), data);
  }

  static getDemoLoginData(){
    if(process.env.VUE_APP_DEMO_MODE === 'dev'){
      return [
        {
          username: "misumi_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "ミスミ株式会社",
        },
        {
          username: "kanto-kikai@testing.bizhiway.cloud",
          password: "Aa0000000",
          name: "関東機械株式会社",
        },
        {
          username: "suzuki_kadan_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "鈴木家電株式会社",
          
        },
        {
          username: "decor_design_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "デコルデザイン株式会社",
        },
      ];
    }
    else if(process.env.VUE_APP_DEMO_MODE === 'test'){
      return [
        {
          username: "misumi_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "ミスミ株式会社",
        },
        {
          username: "kanto-kikai@testing.bizhiway.cloud",
          password: "Aa0000000",
          name: "関東機械株式会社",
        },
        {
          username: "suzuki_kadan_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "鈴木家電株式会社",
          
        },
        {
          username: "decor_design_supplier@jienie.cloud",
          password: "Aa0000000",
          name: "デコルデザイン株式会社",
        },
      ];
    }else if(process.env.VUE_APP_DEMO_MODE === 'staging'){
      return [
        {
          username: "kanto_kikai@staging.bizhiway.cloud",
          password: "Aa0000000",
          name: "関東機械株式会社",
        },
        {
          username: "suzuki_den@staging.bizhiway.cloud",
          password: "Aa0000000",
          name: "鈴木家電株式会社",
        },
        {
          username: "misumi@staging.bizhiway.cloud",
          password: "Aa0000000",
          name: "ミスミ株式会社",
          
        },
        {
          username: "decor_design@staging.bizhiway.cloud",
          password: "Aa0000000",
          name: "デコルデザイン株式会社",
        },
        {
          username: "print_sign@staging.bizhiway.cloud",
          password: "Aa0000000",
          name: "プリントサイン"
        }
      ];
    }else{
      return [];
    }
  }
}