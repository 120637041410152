<template>
  <div v-if="$store.state.logged || $store.state.temporary">
    <b-navbar class="header fixed-top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand to="/" v-if="!$store.state.logged"><img src="@/assets/pb_logo_1.png"/></b-navbar-brand>
      <b-navbar-brand to="/supplier/manage" v-if="$store.state.logged"><img src="@/assets/pb_logo_1.png"/></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav style="flex:1">
          <b-nav-item to="/supplier/manage" v-if="$store.state.logged" class="text-white home-link">
            <font-awesome-icon icon="home"></font-awesome-icon>
            <span class="ml-2">{{$t('HEADER.TO_TOP_PAGE')}}</span>
          </b-nav-item>
          
          <div class="ml-auto text-white" v-if="$store.state.logged">
            <div class="row">
              <div class="position-relative d-inline-block text-center mr-5" style="margin-top: 13px;">
                <a href="javascript:void(0);" @click="toDefautScreenAsNotification()" class="d-inline-block link-header">
                  <font-awesome-icon icon="bell" style="font-size: 1.5rem;"/>
                  <div>
                    <span style="font-size: 14px;">{{ $t("MAIN_MENU.LB_NOTICE") }}</span>
                    <div class="icon-number" v-if="$store.state.user.totalNotice > 0">{{ $store.state.user.totalNotice }}</div>
                  </div>
                </a>
              </div>
              <div class="col-1 d-flex justify-content-center align-items-center"><font-awesome-icon icon="user-circle" style="font-size: 2rem;" class="va-m float-left"></font-awesome-icon></div>
              <div class="col text-right pr-1">
                <small>{{$t('HEADER.COMPANY_CODE')}}</small><br/>
                <span>{{$store.state.user.companyDisplayCode || ''}}</span>
              </div>
              <div class="col text-center pl-1 ml-2" v-if="$store.state.user.organizationCode">
                <template v-if="$store.state.user.organizationName && $store.state.user.organizationName.length > 10">
                  <small>{{ $store.state.user.organizationName.substring(0, 10) }}</small>
                  <span>
                    <tooltip
                      customClass="mw-100 text-left"
                      customStyle="color: inherit;"
                      name="..."
                      :title="$store.state.user.organizationName"
                      data-placement="bottom"
                    ></tooltip>
                  </span>
                </template>
                <template v-else>
                  <small>{{ $store.state.user.organizationName }}</small>
                </template>
                <!-- <small>{{$store.state.user.organizationName || ''}}</small> -->
                <br/>
                <span>{{$store.state.user.organizationCode || ''}}</span>
              </div>
              <div class="col text-center pl-1 ml-2" v-if="$store.state.user.organizationCode">
                <small>{{$t('HEADER.USER_NAME')}}</small>
                <br/>
                <span>{{$store.state.user.name || $t('COMMON.LB_PLACEHOLDER')}}</span>
              </div>
              <div class="col text-center pl-1 ml-2" v-if="!$store.state.user.organizationCode">
                <small>{{$store.state.user.organizationName || ''}}</small>
                <br/>
                <span>{{$store.state.user.name || $t('COMMON.LB_PLACEHOLDER')}}</span>
              </div>
            </div>
          </div>
          <div v-if="$store.state.logged || $store.state.temporary" class="setting-user d-flex justify-content-center align-items-center" :class="$store.state.temporary ? 'ml-auto' : ''" :style="$store.state.temporary ? 'padding-bottom: 16px;' : ''">
            <!-- VinhVN BUG2023-1770  -->
            <font-awesome-icon icon="cog" class="ml-2 mr-2 fa-lg button2" style="margin-top: 15px !important;" type="button" data-toggle="modal" data-target="#settingUserPopup" @click="showModalSettingUser"></font-awesome-icon>
            <div v-if="isDisplaySettingUser" id="settingUserPopup" class="modal fade" role="dialog" data-backdrop="false" @click="settingUser" >
              <div class="modal-dialog setting-user-popup" role="document">
                <div class="modal-content">
                  <div class="modal-body setting-user-content">
                    <template v-if="userOrgSwitch && userOrgSwitch.length > 1">
                      <form>
                        <label class="mb-1 mr-2" for="inlineFormCustomSelectPref">{{ $t("HEADER.HEADER_SWITCH") }}</label>
                        <select2 class="custom-select my-0" id="inlineFormCustomSelectPref" v-model="userOrgMain">
                          <option v-for="(orgSwitch, index) in userOrgSwitch" :key="orgSwitch.code" :value="orgSwitch.code">
                            ({{ index == 0 ? $t("HEADER.HEADER_OPT_DUTY") : $t("HEADER.HEADER_OPT_ADDITIONAL") }}) [{{ orgSwitch.organizationCode }}] {{ orgSwitch.organizationName }} ({{ orgSwitch.companyFullName }})
                          </option>
                        </select2>
                      </form>
                      <div class="dropdown-divider"></div>
                    </template>
                    <router-link class="dropdown-item" v-if="$store.state.logged" to="/profile" @click.native="hideModalSettingUser">{{$t('PROFILE.TITLE')}}</router-link>
                    <a class="dropdown-item" href="javascript:;" v-if="isSingleSignOnUser" v-on:click="singleSignOut" title="SingleSignOut">{{$t('COMMON.LOGOUT')}}</a>
                    <a class="dropdown-item" href="javascript:;" v-else v-on:click="logout">{{$t('COMMON.LOGOUT')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <b-nav-item-dropdown @show="setUserOrg()" right class="d-flex align-items-center justify-content-center mt-2" v-if="$store.state.logged || $store.state.temporary">
            <template v-slot:button-content>
              <font-awesome-icon icon="cog" class="ml-4 fa-lg" ></font-awesome-icon>
            </template>
            <template v-if="userOrgSwitch && userOrgSwitch.length > 1">
              <form class="ml-2 mr-2">
                <label class="mb-1 mr-2" for="inlineFormCustomSelectPref">{{ $t("HEADER.HEADER_SWITCH") }}</label>
                <select class="custom-select my-0" id="inlineFormCustomSelectPref" v-model="userOrgMain">
                  <option v-for="(orgSwitch, index) in userOrgSwitch" :key="orgSwitch.code" :value="orgSwitch.code">
                    ({{ index == 0 ? $t("HEADER.HEADER_OPT_DUTY") : $t("HEADER.HEADER_OPT_ADDITIONAL") }}) [{{ orgSwitch.organizationCode }}] {{ orgSwitch.organizationName }} ({{ orgSwitch.companyFullName }})
                  </option>
                </select>
              </form>
              <div class="dropdown-divider"></div>
            </template>
            <b-dropdown-item v-if="$store.state.logged" to="/profile">{{$t('PROFILE.TITLE')}}</b-dropdown-item>
            <b-dropdown-item v-if="isSingleSignOnUser" v-on:click="singleSignOut" title="SingleSignOut">{{$t('COMMON.LOGOUT')}}</b-dropdown-item>
            <b-dropdown-item v-else v-on:click="logout">{{$t('COMMON.LOGOUT')}}</b-dropdown-item>
          </b-nav-item-dropdown> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import LoginModel from '@/model/Login.js';
import select2 from '@/components/common/Select2.vue';
import tooltip from '@/components/common/Tooltip.vue';

export default {
  components: { select2, tooltip },
  data() {
    return {
      j2Logged: this.$store.state.j2Logged,
      j2Url: process.env.VUE_APP_J2_URL,
      userOrgSwitch: this.$store.state.user.userOrgSwitch,
      userOrgMain: this.$store.state.user.code,
      isDisplaySettingUser: false
    };
  },
  created(){
  },
  watch: {
    userOrgMain() {
      if(this.$store.state.user.code === this.userOrgMain) return;
      this.hideModalSettingUser();
      var newUser = this.userOrgSwitch.find(u => u.code === this.userOrgMain)
      LoginModel.switchUserOrg(newUser).then(result => {
        this.$store.commit("login", {
          accessToken: result.headers.authorization,
          user: result.data,
        });
        if(this.$store.state.user.status.accountCreated){
          this.$router.push({ name: "MainMenu" });
          this.$router.go(0);
        }else{
          this.$router.push({ name: "InviterPolicy" });
        }
      }).catch(reason => console.error(reason));
    }  
  },
  computed: {
    singleSignOn() {
      return this.$store.state.sso || {};
    },
    isSingleSignOnUser() {
      return Object.keys(this.singleSignOn).length > 0 && (typeof this.singleSignOn.isSyncData === "boolean" || (this.singleSignOn.singleSignOnList && this.singleSignOn.singleSignOnList.length > 0));
    }
  },
  methods: {
    logout(){
      this.hideModalSettingUser();
      this.$store.commit('logout');
      if (this.j2Logged) {
        window.location.replace(this.j2Url + '/SSO/J2Logout?SystemName=Bizhiway');
      } else {
        this.$router.push({ path: "/" });
      }
    },
    singleSignOut() {
      this.hideModalSettingUser();
      if (this.isSingleSignOnUser) {
        this.$router.push({ name: "sso_logout" }).catch(() => {
          window.location.replace("/");
        });
      }
    },
    setUserOrg() {
      this.userOrgMain = this.$store.state.user.code
      this.userOrgSwitch = this.$store.state.user.userOrgSwitch
    },
    toDefautScreenAsNotification() {
      const element = document.getElementById("Notification");
      if (element) {
        element.scrollIntoView();
      } else {
        this.$router.push({ name: "MainMenu" });
        setTimeout(() => {
          const element = document.getElementById("Notification");
          if (element) {
            element.scrollIntoView();
          }
        }, 500);
      }
      
    },
    // VinhVN BUG2023-1770
    settingUser(e) {
      e.stopPropagation();
    },
    // VinhVN BUG2023-1770 add function show/hide popup setting user and check click outside
    showModalSettingUser() {
      this.isDisplaySettingUser = true;
      this.setUserOrg();
      document.addEventListener('mouseup', this.closeModalOnClickOutside);
    },
    closeModalOnClickOutside(event) {
      if (event.target.id == 'settingUserPopup') {
        this.hideModalSettingUser();
      }
    },
    hideModalSettingUser() {
      var element = document.getElementsByTagName("BODY")[0];
      element.classList.remove("modal-open");
      element.style.paddingRight = null;
      var elementHeader = document.getElementsByClassName("header")[0];
      elementHeader.style.paddingRight = null;
      document.removeEventListener('mouseup', this.closeModalOnClickOutside);
      this.isDisplaySettingUser = false;
    }
  }
}
</script>

<style scoped lang="scss">
  .header{
    height: 70px;
    position: fixed;
    z-index: 500;
    // width: 100%;
    background-color: #03041f !important;
    border-bottom: #ffb300 solid 4px;
    img{
      width: auto;
      height: 50px;
      margin-left: 87px;
    }
    .home-link {
      margin-left: 25px;
      padding-left: 25px;
      display:flex;
      justify-content: center;
      align-items:center;
      font-size: 0.7rem;
      border-left: 1px solid #37384c;
      svg { font-size: 1rem; }
    }
    .icon-number {
      background-color: #ef5350;
      position: absolute;
      top: -3px;
      right: 10px;
      color: #fff;
      padding: 1px 5px;
      border-radius: 12px;
      font-size: 10px;
    }
    .link-header {
      margin-left: 25px;
      padding-left: 25px;
      display:flex;
      justify-content: center;
      align-items:center;
      font-size: 0.7rem;
      svg { font-size: 1rem; };
      text-decoration: none; 
      color: rgba(255,255,255,.7);
    }
    .link-header:hover{
      color: white;
    }
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
  .setting-user-popup {
  margin-right: 0px;
  margin-top: 65px;
  width: 20%;
  color: black !important;
  }
  .setting-user {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-left: 1rem;
  height: 55px;
}
.setting-user .button2 {
  margin-top: 10px;
  color: #ffffff;
}
.setting-user-content {
  min-width: 15rem;
  font-size: 0.8rem;
}
.setting-user-content form {
  padding: 0 10px;
}
.setting-user-content label {
  font-weight: bold;
}
.setting-user-content .custom-select {
  font-size: 0.8rem;
}
.setting-user-content .dropdown-item {
  padding: 0.25rem 0.7rem;
  color: #045eb4 !important;
}
.setting-user-content .dropdown-item:focus {
  color: #045eb4 !important;
}
.setting-user-content .dropdown-item:active {
  color: #ffffff !important;
}
</style>
