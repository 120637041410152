const numeral = require('numeral');
const moment = require('moment');

const formatCurrency = function (value) {
  return numeral(value).format("0,0");
}

const formatCurrencyView = function(value){
  if(value){
    return numeral(value).format("0,0") + '円';
  }else return "≪未登録≫";
}

const formatDate = function(value){
  if(value){
    return moment(value).format('YYYY-MM-DD');
  }else return "";
}

const formatCapitalCurrencyView = function(value){
  if(value != null){
    return numeral(value / 10000).format("0,0") + '万円';
  }else return "≪未登録≫";
}

const formatLongText = function(value){
  if(value){
    if(value.length > 40) return value.substr(0,40) + "…";
    else return value;
  }else return "≪未登録≫";
}

export {
  formatCurrency,
  formatCurrencyView,
  formatCapitalCurrencyView,
  formatLongText,
  formatDate
}