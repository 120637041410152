<template>
  <a v-if="name" :class="{'font-weight-bold': boldText}" :style="customStyle" href="javascript:;" v-html="isUseHtml ? name : cleanMessage" @click="eventClick"></a>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
<script>
export default {
  props: {
    name: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ""
    },
    customStyle: {
      type: String,
      default: ""
    },
    boldText: {
      type: Boolean,
      default: false
    },
    delayTime: {
      type: Number,
      default: 0
    },
    // Case hard-code html : keep v-html
    // Link bug: https://jira.jienie.cloud/browse/DEV-7760
    isUseHtml: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: "auto"
    }
  },
  watch: {
    title() {
      $(this.$el).attr("data-original-title", this.title);
    }
  },
  computed:{
    cleanMessage() {
      return this.$sanitize(this.name);
    }
  },
  mounted() {
    $(this.$el).tooltip({
      title: this.title,
      delay: { "show": 0, "hide": this.delayTime },
      html: true,
      // TuanNH 2021/06/19 https://jira.jienie.cloud/browse/DEV-1527
      placement: this.placement,
      template: `<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner ${this.customClass}"></div></div>`
    });
  },
  methods: {
    eventClick() {
      this.$emit("click");
    }
  }
};
</script>
